<template>
    <v-container fluid  class="grey lighten-5 py-10 px-0 " id="Busquedas">
      <v-row no-gutters class="" >
        <v-col cols=12 md=6 class="d-flex flex-column justify-space-around"  >
            <div class="d-flex justify-center px-5" >
              <span class="text-responsive-h primary--text font-weight-normal ">BÚSQUEDAS</span>
            </div>
        </v-col>
 
        <v-col cols=12 md=6 class="d-flex  justify-space-around " >
              <v-sheet
                v-for = "(item,index) of Menu" :key="`title${index}`"
                class=" under-line d-flex flex-column align-center "
                :class="tipoBusqueda==index ? 'active':''"
                @click="changeTipoBusqueda(index,item.id)"
                color="transparent"
              

                
              >
                  <div >
                    <v-img :src="item.icon" class="my-5"  ></v-img>
                  </div>
                  <div class="text-center">{{item.title}}</div>
                  
                
              </v-sheet>

        </v-col>
      </v-row>



      <div class="mt-5 text-center grey lighten-3 py-10 primary--text text-h6" v-if="Busquedas.length==0">
        SIN DATOS PARA MOSTRAR
      </div>
      <div v-if="false" v-for="(busqueda, index) of Busquedas" :key="`rowBusqueda${index}`">
        <v-row no-gutters :class="index==0 ? 'mt-10' : 'mt-5'" >
          <v-col cols=12 sm=3  lg=2  xl=2    order=1 order-sm=0>
            <div v-for="(link, idx) of busqueda.listLinks" class="d-block mb-10" key="`link${index}_${idx}`">
              <div style="width:100%; " class="px-5 px-md-10 px-xl-15 mt-10 mt-sm-0" >
                <a :href="link.link" target="_blank">
                <v-img
                  content
                  :src="`/${link.path}/${link.fileName}`"  
                  eager  
                  max-width="120px"        
                >
                </v-img>
                </a>
              </div>
              <div style="width:100%" class="px-5 px-md-10 px-xl-15 " >
                <v-btn rounded x-small color="primary" class="mt-5 caption" :href="link.link" target="_blank">Postulate!!!</v-btn>
              </div>
            </div>


          </v-col>
          <v-col cols=12 sm=9  lg=10 xl=10 class="px-2 px-sm-0" >
            <v-row no-gutters>
              <v-col cols=12 class="body-1 white--text font-weight-normal py-2 pl-2 primary px-2">{{busqueda.titulo}}</v-col>
              <v-col cols=12 class="body-2 px-2 ">
                <p class="my-2 font-weight-normal">{{busqueda.subtitulo}}</p>
                <p class="my-2 font-weight-normal" v-html="busqueda.detalle"></p>
                <p class="mt-4 mb-1 primary--text font-weight-medium">¿Qué buscamos?</p>
                <p v-for="(item, index) of busqueda.listQBuscamos" class="mb-1" :key="`qb${index}`" >{{item.texto}}</p>

                <p class="mt-4 mb-1 primary--text font-weight-medium">¿Para qué?</p>
                <p v-for="(item, index) of busqueda.listParaQue" class="mb-1" :key="`pq${index}`" >{{item.texto}}</p>

                <p class="mt-4 mb-1 primary--text font-weight-medium">¿Qué ofrecemos?</p>
                <p v-for="(item, index) of busqueda.listQOfrecemos" class="mb-1" :key="`qo${index}`" >{{item.texto}}</p>
              </v-col>
              
            </v-row>
          </v-col>
        </v-row>
      </div>
      <div v-for="(busqueda, index) of lstBusquedas" :key="`rowBusqueda2${index}`" class="px-2">
        <v-row no-gutters class=" mt-10">
          <v-col cols=12 md="10" class="offset-0 offset-md-1">
            <v-card tile class="" :key="`card${index}`">
              <v-card-title class="body-1 white--text font-weight-normal py-2 pl-2 primary px-2">
                {{busqueda.titulo}}
              </v-card-title>
              <v-card-text>
                <p v-if="busqueda.subtitulo" class="my-2 font-weight-normal">{{busqueda.subtitulo}}</p>
                <p v-if="busqueda.detalle" class="my-2 font-weight-normal" v-html="busqueda.detalle"></p>
              </v-card-text>
              <v-divider />
              <v-card-text class="d-flex justify-end px-5">
                <v-btn rounded x-small color="primary" class="my-1 caption" @click="openBusqueda(busqueda)">VER MÁS</v-btn>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <template>
          <div class="text-center mt-2">
            <v-pagination
              v-model="pager.page"
              :length="Busquedas.length"
              :total-visible="pager.totalVisible"
            ></v-pagination>
          </div>
        </template>
      </div>
      <Busqueda v-if="viewBusqueda.open" @close="viewBusqueda.open=false" :busqueda="viewBusqueda.data"></Busqueda>
    </v-container>
</template>

<script>
import 'animate.css'
import {format} from 'date-fns'

import Busqueda from "@/components/Busqueda"

export default {
  name: 'Busquedas',
  components: {Busqueda},

 

  data: () => ({

    tipoBusqueda:0,
    ROOT_API:process.env.VUE_APP_ROOT_API,
    busquedas:[],
    pager:{page:1, totalVisible:10},
    tipoID:2,

    viewBusqueda:{open:false, data:[]},

    Menu:[
      {id:2,title:'Búsquedas',icon:'/images/iconos/otras_busquedas.png',text:true, tile:true, replace:true, ripple:false, rounded:false,class:"btn-line body-2 black--text",link:'',type:''},
      {id:1,title:'Búsquedas Gerenciales',icon:'/images/iconos/busquedas.png',text:true, tile:true, replace:true, ripple:false, rounded:false,class:"btn-line body-2 black--text",link:'',type:''},


    ],

  }),

  created: function () {
    this.loadItems()
  },

  mounted: function () {
  },

  watch: {

  },
  computed:{
    Busquedas: function () {
      return this.busquedas.filter(b => b.tipoBusqueda==this.tipoID)
    },


     lstBusquedas: function ()  {
      let ini, fin
      ini=(this.pager.page-1)*this.pager.totalVisible
      fin=ini+this.pager.totalVisible
      return this.Busquedas.slice(ini,fin)
    },   

  },

  methods: {
    openBusqueda (busqueda){
      this.viewBusqueda={open:true, data:busqueda}
    },

    changeTipoBusqueda(index, id){
      this.tipoBusqueda=index
      this.tipoID=id
    },

    async loadItems () {
      let opt={
        method: 'GET', 
        mode: 'cors'
      }
      let response = await fetch(`${this.ROOT_API}busquedas`, opt);

      if (response.ok){
           let r = await response.json()

           this.busquedas = r.map(b => {
              let busqueda={}
              busqueda.tipoBusqueda=b.tipoID
              busqueda.titulo=b.titulo 
              busqueda.subtitulo=b.subtitulo
              busqueda.detalle=b.detalle

              if (b.fDesde) busqueda.fDesde = format(new Date(b.fDesde),'yyyy-MM-dd')
              if (b.fHasta) busqueda.fHasta = format(new Date(b.fHasta),'yyyy-MM-dd')

              busqueda.listQBuscamos = b.items.filter(x => x.tipoID==1)
              busqueda.listParaQue = b.items.filter(x => x.tipoID==2)
              busqueda.listQOfrecemos = b.items.filter(x => x.tipoID==3)
              busqueda.listLinks = b.links   
              
              return busqueda

           })


           console.log("datos-->",this.busquedas)

   
      } 
    },




    offset (idx) {
      return (idx % 2 == 0 ? 1 : 2 )
    },

    onResize () {
      //console.log(this.$vuetify.breakpoint)
      let {xs, sm, md, lg, xl,mobile} =  this.$vuetify.breakpoint      
      return sm || xs
      //this.$store.dispatch("resizeApp",{ x: window.innerWidth, y: window.innerHeight, xs, sm, md, lg, xl,mobile })
    },
  },

};
</script>

<style lang="scss" scope>

.text-busqueda {
  font-size: clamp(3rem, 10vw - 2rem,6rem) !important;
  line-height: .7!important;
}


.under-line.active {
      ::after {
        transform: scaleX(1);  
        opacity: 1;
       }
    }

  .under-line {
    position: relative!important;
    text-transform: none!important;

    @media only screen and (max-width:  600px) {
      width: 30%!important;
    }






  
    ::after{
      position: absolute;
      content: '';
      color:transparent;
      width: 100%;
      bottom:-10px;
      left:0;
      border-bottom: solid 2px #C9D401;
      transition: transform 250ms ease-in-out;
      transform: scaleX(0);
    }

    :hover {
      cursor: pointer;
      &::after { 
        transform: scaleX(1); 
        color: #C9D401 !important;
      }
    }
    
  }









.divider_green  {
  position: relative;
  
  &::before {
    content:"";
    position: absolute;
    bottom:0;
    background: linear-gradient(to right, #c9d401 0%, #FFFF 80%);//radial-gradient(circle, #c9d401 0%, rgba(255,255,255,1) 98%);
    width: calc(100% - 24px);
    height: 2px;

  }
}

.v-card__title {
  word-break: normal!important;
}


.v-card {
  position:relative;


  &::before {
    position:absolute;
    //content:'';
    top:0;
    left:-6px;
    width: 6px;
    height: 100%;
    //background:radial-gradient(ellipse at center, red 0, transparent 50%);
    background-color: #AAA;
    filter:blur(1px);

    //background: radial-gradient(ellipse at top, #e66465, transparent),
    //        radial-gradient(ellipse at bottom, #4d9f0c, transparent);
    
    border-radius: 100% 0 0 100% / 50%;
    //0 100% 100% 0 / 50%

  }
}

</style>
